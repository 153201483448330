import { template as template_85c031d4f620487e9aa6d4e3138d37f3 } from "@ember/template-compiler";
const FKText = template_85c031d4f620487e9aa6d4e3138d37f3(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
